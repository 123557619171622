import React, { useState } from 'react';
import './Signin.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Signin = ({ setUser }) => {
  const [formData, setFormData] = useState({ email: '', password: '' });
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://kalbwotta-backend.vercel.app/api/users/login', formData);

      const { token, user } = response.data;
      console.log('User data:', user);
      if (user) {
        localStorage.setItem('token', token);
        localStorage.setItem('user', JSON.stringify(user));
        setUser(user);

        if (user.role === 'admin') {
          navigate('/admin-dashboard');
        } else {
          navigate('/home');
        }
      } else {
        setErrorMessage('Incorrect email or password');
      }
    } catch (error) {
      console.error('Error:', error);
      setErrorMessage('Wrong Email or Password');
    }
  };

  return (
    <div className="signin-container">
      <form onSubmit={handleSubmit} className="signin-form">
        <h2>Sign In</h2>
        <p>Email</p>
        <input
          type="email"
          placeholder="Email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
        />
        <p>Password</p>
        <input
          type="password"
          placeholder="Password"
          name="password"
          value={formData.password}
          onChange={handleChange}
          required
        />
        <input type="submit" value="Sign In" className="signin-submit" />
        {errorMessage && <p className="signin-error">{errorMessage}</p>}
        <div className="signin-footer">
          <p>
            <a href="/sign-up">Don't have an account?</a>
          </p>
          <p className="forget-password">
            <a href="https://www.facebook.com/kalbwotta?mibextid=LQQJ4d">Forgot Password?</a>
          </p>
        </div>
      </form>
    </div>
  );
};

export default Signin;
