import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMagnifyingGlass,
  faUser,
  faShoppingCart,
  faDog,
  faCat,
  faTags,
  faTimes,
  faBars,
  faHome,
  faCreditCard,
  faChevronDown,
  faStethoscope
} from "@fortawesome/free-solid-svg-icons";
import { Pill } from "lucide-react";
import { faFacebook, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import CartDrawer from "../components/CartDrawer";
import logo from "../images/logo.png";
import './Header.css';

const API_BASE_URL = "https://kalbwotta-backend.vercel.app";

function Header({ user }) {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [error, setError] = useState("");
  const [openDropdown, setOpenDropdown] = useState(null);
  const [showResults, setShowResults] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const searchRef = useRef(null);
  const navigate = useNavigate();

  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
    setShowResults(true);
  };

  const toggleDropdown = (index, event) => {
    event.preventDefault();
    setOpenDropdown(openDropdown === index ? null : index);
  };

  const handleClickOutside = (event) => {
    if (searchRef.current && !searchRef.current.contains(event.target)) {
      setShowResults(false);
    }
    if (!event.target.closest('.kw-custom-menu-item')) {
      setOpenDropdown(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (searchQuery.trim()) {
      const fetchProducts = async () => {
        try {
          const response = await axios.get(
            `${API_BASE_URL}/api/products/search-products/${searchQuery}`
          );
          setSearchResults(response.data);
          setError("");
        } catch (error) {
          setError(
            error.response?.data?.error || "An error occurred while searching"
          );
          setSearchResults([]);
        }
      };

      const debounceTimeout = setTimeout(fetchProducts, 300);
      return () => clearTimeout(debounceTimeout);
    } else {
      setSearchResults([]);
    }
  }, [searchQuery]);

  const clearSearch = () => {
    setSearchQuery("");
    setSearchResults([]);
    setShowResults(false);
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  return (
    <div>
      <div className="kw-unique-top-bar">
        <div className="kw-unique-top-bar-container">
          <div className="kw-unique-contact-info">
            <span>Email: info@kalbwotta.clinic</span>
            <span>Phone: +201030503023</span>
          </div>
          <div className="kw-unique-social-icons">
            <a href="https://www.facebook.com/kalbwotta/" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faFacebook} />
            </a>
            <a href="https://wa.me/201030503023" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faWhatsapp} />
            </a>
          </div>
        </div>
      </div>

      <div className="kw-navbar">
        <div className="kw-logo">
          <img src={logo} alt="Logo" />
        </div>
        <div className="kw-searchbar" ref={searchRef}>
          <input
            type="search"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearchInputChange}
          />
          <button className="kw-search">
            <FontAwesomeIcon icon={faMagnifyingGlass} />
          </button>
          {searchQuery && (
            <button className="kw-clear-search" onClick={clearSearch}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
          )}
          {showResults && searchResults.length > 0 && (
            <div className="kw-search-results">
              <ul>
                {searchResults.map((product) => (
                  <li key={product._id} onClick={() => navigate(`/products/${product._id}`)}>
                    <div className="kw-search-result-item">
                      {product.imageUrls?.[0] && (
                        <img
                          src={product.imageUrls[0]}
                          alt={product.name}
                          className="kw-search-result-image"
                        />
                      )}
                      <div className="kw-search-result-info">
                        <h3 className="kw-search-result-name">{product.name}</h3>
                        <p className="kw-search-result-price">
                          <FontAwesomeIcon icon={faTags} className="kw-price-icon" />
                          {product.price} LE
                        </p>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}
          {error && <p className="kw-error-message">{error}</p>}
        </div>

        <div className="kw-login-and-cart">
          <div className="kw-login">
            {user ? (
              <>
                <FontAwesomeIcon icon={faUser} />
                <Link to="/user-profile" className="kw-username">
                  {user.username}
                </Link>
              </>
            ) : (
              <button className="kw-login-btn">
                <FontAwesomeIcon icon={faUser} />
                <Link to="/sign-in">Login</Link>
              </button>
            )}
          </div>

          <div className="kw-cart">
            <CartDrawer />
          </div>
        </div>
      </div>

      <div className="kw-custom-bottom-bar">
        <button className="kw-mobile-menu-toggle" onClick={toggleMobileMenu}>
          <FontAwesomeIcon icon={faBars} />
        </button>
        <ul className={`kw-custom-bottom-bar-menu ${mobileMenuOpen ? 'open' : ''}`}>
          <li className="kw-custom-menu-item">
            <Link to="/">
              <FontAwesomeIcon icon={faHome} /> Home
            </Link>
          </li>
          <li className="kw-custom-menu-item">
            <a href="https://www.kalbwotta.clinic" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faStethoscope} /> Clinic
            </a>
          </li>
          <li className={`kw-custom-menu-item kw-custom-dropdown ${openDropdown === 0 ? 'open' : ''}`}>
            <span onClick={(e) => toggleDropdown(0, e)}>
              <FontAwesomeIcon icon={faDog} /> Dogs
              <FontAwesomeIcon icon={faChevronDown} className="kw-dropdown-arrow" />
            </span>
            <ul className="kw-custom-dropdown-content">
              <li>
                <Link to="/products?category=Dog&subcategory=Dog Food">
                  <FontAwesomeIcon icon={faDog} className="kw-dropdown-icon" />
                  <span className="kw-category-title">Dog Food</span>
                </Link>
                <ul>
                  <li>
                    <Link to="/products?category=Dog&subcategory=Dog Food&subsubcategory=Dry Food">
                      <FontAwesomeIcon icon={faDog} className="kw-dropdown-icon" />
                      Dry Dog Food
                    </Link>
                  </li>
                  <li>
                    <Link to="/products?category=Dog&subcategory=Dog Food&subsubcategory=Wet Food">
                      <FontAwesomeIcon icon={faDog} className="kw-dropdown-icon" />
                      Wet Dog Food
                    </Link>
                  </li>
                  <li>
                    <Link to="/products?category=Dog&subcategory=Dog Food&subsubcategory=Treats">
                      <FontAwesomeIcon icon={faDog} className="kw-dropdown-icon" />
                      Treats
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/products?category=Dog&subcategory=Health And Hapiness">
                  <FontAwesomeIcon icon={faDog} className="kw-dropdown-icon" />
                  <span className="kw-category-title">Health</span>
                </Link>
                <ul>
                  <li>
                    <Link to="/products?category=Dog&subcategory=Health And Hapiness&subsubcategory=Supplements">
                      <FontAwesomeIcon icon={faDog} className="kw-dropdown-icon" />
                      Supplements
                    </Link>
                  </li>
                  <li>
                    <Link to="/products?category=Dog&subcategory=Health And Hapiness&subsubcategory=Training Tools">
                      <FontAwesomeIcon icon={faDog} className="kw-dropdown-icon" />
                      Training Tools
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/products?category=Dog&subcategory=Dog Accessories">
                  <FontAwesomeIcon icon={faDog} className="kw-dropdown-icon" />
                  <span className="kw-category-title">Dog Accessories</span>
                </Link>
                <ul>
                  <li>
                    <Link to="/products?category=Dog&subcategory=Dog Accessories&subsubcategory=Toys">
                      <FontAwesomeIcon icon={faDog} className="kw-dropdown-icon" />
                      Toys
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li className={`kw-custom-menu-item kw-custom-dropdown ${openDropdown === 1 ? 'open' : ''}`}>
            <span onClick={(e) => toggleDropdown(1, e)}>
              <FontAwesomeIcon icon={faCat} /> Cats
              <FontAwesomeIcon icon={faChevronDown} className="kw-dropdown-arrow" />
            </span>
            <ul className="kw-custom-dropdown-content">
              <li>
                <Link to="/products?category=Cat&subcategory=Cat Food">
                  <FontAwesomeIcon icon={faCat} className="kw-dropdown-icon" />
                  <span className="kw-category-title">Cat Food</span>
                </Link>
                <ul>
                  <li>
                    <Link to="/products?category=Cat&subcategory=Cat Food&subsubcategory=Dry Food">
                      <FontAwesomeIcon icon={faCat} className="kw-dropdown-icon" />
                      Dry Cat Food
                    </Link>
                  </li>
                  <li>
                    <Link to="/products?category=Cat&subcategory=Cat Food&subsubcategory=Wet Food">
                      <FontAwesomeIcon icon={faCat} className="kw-dropdown-icon" />
                      Wet Cat Food
                    </Link>
                  </li>
                  <li>
                    <Link to="/products?category=Cat&subcategory=Cat Food&subsubcategory=Treats">
                      <FontAwesomeIcon icon={faCat} className="kw-dropdown-icon" />
                      Treats
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/products?category=Cat&subcategory=Health And Cleanliness">
                  <FontAwesomeIcon icon={faCat} className="kw-dropdown-icon" />
                  <span className="kw-category-title">Health & Cleanliness</span>
                </Link>
                <ul>
                  <li>
                    <Link to="/products?category=Cat&subcategory=Health And Cleanliness&subsubcategory=Litter">
                      <FontAwesomeIcon icon={faCat} className="kw-dropdown-icon" />
                      Litter
                    </Link>
                  </li>
                  <li>
                    <Link to="/products?category=Cat&subcategory=Health And Cleanliness&subsubcategory=Supplements">
                      <FontAwesomeIcon icon={faCat} className="kw-dropdown-icon" />
                      Supplements
                    </Link>
                  </li>
                  <li>
                    <Link to="/products?category=Cat&subcategory=Health And Cleanliness&subsubcategory=Grooming Tools">
                      <FontAwesomeIcon icon={faCat} className="kw-dropdown-icon" />
                      Grooming Tools
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/products?category=Cat&subcategory=Cat Accessories">
                  <FontAwesomeIcon icon={faCat} className="kw-dropdown-icon" />
                  <span className="kw-category-title">Cat Accessories</span>
                </Link>
                <ul>
                  <li>
                    <Link to="/products?category=Cat&subcategory=Cat Accessories&subsubcategory=Litter Box">
                      <FontAwesomeIcon icon={faCat} className="kw-dropdown-icon" />
                      Litter Box
                    </Link>
                  </li>
                  <li>
                    <Link to="/products?category=Cat&subcategory=Cat Accessories&subsubcategory=Toys">
                      <FontAwesomeIcon icon={faCat} className="kw-dropdown-icon" />
                      Toys
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li className="kw-custom-menu-item">
            <Link to="/products?category=Pharmacy">
              <Pill className="kw-icon" /> Pharmacy
            </Link>
          </li>
          <li className="kw-custom-menu-item">
            <Link to="/checkout">
              <FontAwesomeIcon icon={faCreditCard} /> Checkout
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Header;