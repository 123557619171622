import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useCart } from "../contexts/cartContext";
import "./ProductDetailPage.css"; // CSS file for styling
import { FaShareAlt, FaFacebook, FaWhatsapp, FaInstagram } from "react-icons/fa";

const API_BASE_URL = "https://kalbwotta-backend.vercel.app";

function ProductDetailPage() {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const { addToCart } = useCart();
  const [isSharePopupOpen, setIsSharePopupOpen] = useState(false);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/products/get-product/${id}`);
        setProduct(response.data);
      } catch (error) {
        console.error("Error fetching product details:", error);
      }
    };

    fetchProduct();
  }, [id]);

  const handleAddToCart = () => {
    if (product) addToCart({ ...product, quantity });
  };

  const handleQuantityChange = (type) => {
    setQuantity((prev) => (type === "increment" ? prev + 1 : Math.max(1, prev - 1)));
  };

  const handleShareClick = () => {
    setIsSharePopupOpen(!isSharePopupOpen);
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(window.location.href);
    alert("Product link copied to clipboard!");
  };

  return (
    <div className="product-detail-page">
      {product ? (
        <div className="product-detail-container">
          {/* Left Section: Product Image */}
          <div className="product-image-container">
            <img src={product.imageUrls[0]} alt={product.name} className="product-image1" />
          </div>

          {/* Right Section: Product Info */}
          <div className="product-info-container">
            <h1 className="product-title">{product.name}</h1>
            <p className="product-price">LE {product.price.toFixed(2)}</p>


            {/* Quantity Selector */}
            <div className="quantity-selector">
              <label>Quantity</label>
              <div className="quantity-controls">
                <button onClick={() => handleQuantityChange("decrement")}>-</button>
                <span>{quantity}</span>
                <button onClick={() => handleQuantityChange("increment")}>+</button>
              </div>
            </div>

            {/* Action Buttons */}
            <div className="action-buttons">
              <button onClick={handleAddToCart} className="add-to-cart-button">
                ADD TO CART
              </button>
            </div>

            {/* Product Description */}
            <div className="product-description">
              <h2>Description</h2>
              <p>{product.description}</p>
              <hr className="description-divider" />
            </div>

            {/* Share Icon */}
            <div className="share-section">
              <FaShareAlt className="share-icon" onClick={handleShareClick} />
            </div>

            {/* Share Popup */}
            {isSharePopupOpen && (
              <div className="share-popup">
                <button className="copy-link-button" onClick={handleCopyLink}>
                  Copy Product Link
                </button>
                <div className="share-icons">
                  <a
                    href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaFacebook className="social-icon facebook-icon" />
                  </a>
                  <a
                    href={`https://api.whatsapp.com/send?text=${window.location.href}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaWhatsapp className="social-icon whatsapp-icon" />
                  </a>
                  <a
                    href={`https://www.instagram.com/?url=${window.location.href}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaInstagram className="social-icon instagram-icon" />
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <p>Loading product details...</p>
      )}
    </div>
  );
}

export default ProductDetailPage;
