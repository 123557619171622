import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import FilterSidebar from "../components/FilterSideBar";
import { useCart } from "../contexts/cartContext";
import axios from "axios";
import "./ProductList.css";

const API_BASE_URL = "https://kalbwotta-backend.vercel.app";

function ProductListing() {
  const [products, setProducts] = useState([]);
  const [filters, setFilters] = useState({});
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { addToCart } = useCart();

  const [title, setTitle] = useState("Products");
  const [showSidebar, setShowSidebar] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const category = searchParams.get("category") || "";
    const subcategory = searchParams.get("subcategory") || "";

    if (subcategory) setTitle(`${subcategory} Products`);
    else if (category) setTitle(`${category} Products`);
    else setTitle("Products");

    const newFilters = {
      category,
      subcategory,
      subsubcategory: searchParams.get("subsubcategory") || "",
      minPrice: searchParams.get("minPrice") || "",
      maxPrice: searchParams.get("maxPrice") || "",
      brand: searchParams.get("brand") || "",
      rating: searchParams.get("rating") || "",
    };
    setFilters(newFilters);
    fetchFilteredProducts(newFilters);
  }, [location.search]);

  const fetchFilteredProducts = async (filters) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/products/filter-products`, { params: filters });
      setProducts(response.data);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const handleFilterChange = (newFilters) => {
    const queryParams = new URLSearchParams(newFilters);
    navigate(`/products?${queryParams.toString()}`);
  };

  const handleProductClick = (productId) => navigate(`/products/${productId}`);

  const toggleSidebar = () => setShowSidebar(!showSidebar);

  return (
    <div className="kw-product-page">
      {isMobile ? (
        <>
          <button className="kw-filter-button" onClick={toggleSidebar}>
            <i className="fas fa-filter"></i> Filter
          </button>
          {showSidebar && (
            <div className="kw-filter-sidebar-overlay" onClick={toggleSidebar}>
              <div className="kw-filter-sidebar-popup" onClick={(e) => e.stopPropagation()}>
                <button className="kw-close-filter-btn" onClick={toggleSidebar}>
                  &times;
                </button>
                <FilterSidebar onFilterChange={handleFilterChange} />
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="kw-sidebar-container">
          <FilterSidebar onFilterChange={handleFilterChange} />
        </div>
      )}
      <div className="kw-product-list-container">
        <h2 className="kw-product-title">{title}</h2>
        <div className="kw-product-list">
          {products.length > 0 ? (
            products.map((product) => (
              <div
                key={product._id}
                className="kw-product-card"
                onClick={() => handleProductClick(product._id)}
              >
                <div className="kw-product-image-wrapper">
                  <div className="kw-product-image-container">
                    <img
                      src={product.imageUrls[0]}
                      alt={product.name}
                      className="kw-product-image"
                    />
                  </div>
                </div>
                <h4 className="kw-product-name">{product.name}</h4>
                <p className="kw-product-price">{product.price} LE</p>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    addToCart(product);
                  }}
                  className="kw-buy-button"
                >
                  Add to Cart
                </button>
              </div>
            ))
          ) : (
            <p className="kw-no-products">No products found.</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default ProductListing;